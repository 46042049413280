.works {
    @include title;

    .all-block {
        display: flex;
        overflow: hidden;
        padding-block: 50px;
        width: 100%;
        gap: 30px;

        .block-works {
            display: flex;
            justify-content: center;
            gap: 30px;

            .work__card {
                width: 500px;

                @media only screen and (max-width: $ms) {
                    width: 250px;
                }

                img {
                    display: flex;
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                }

                .work__desc {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    h4 {
                        color: $bl;
                        font-weight: 500;
                        font-size: 1.25rem;
                    }

                    @media only screen and (max-width: $ms) {
                        h5 {
                            font-size: 1rem;
                        }

                        p {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
}