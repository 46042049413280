.info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-block: 100px;
    padding-inline: 20px;
    gap: 100px;

    @media only screen and (max-width: $ss) {
        padding: 0;
    }

    &.row-reverse {
        flex-direction: row-reverse;
    }

    img {
        width: 425px;
        height: 650px;
        object-fit: cover;

        @media only screen and (max-width: $ss) {
            width: 100%;
            height: 500px;
        }
    }

    .info-text {
        gap: 20px;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 2rem;
            text-align: center;
        }

        p {
            max-width: 650px;
            letter-spacing: .01rem;
            font-size: 18px;
            line-height: 2rem;
            margin-bottom: 10px;
            padding: 15px;
            
            @media only screen and (max-width: $ss) {
                line-height: 1.2rem;
            } 

        }

        .info-text__warning {
            display: flex;
            flex-direction: column;
            background-color: $grey;
            box-shadow: 10px 20px 10px rgba(0, 0, 0, .5);

            svg {
                width: 75px;
                height: 75px;
                padding: 15px;
            }
        }

        @media only screen and (max-width: $ss) {
            padding-inline: 20px;

            h3 {
                font-size: 1.5rem;
            }

            p {
                font-size: 11px;
            }
        }
    }
}