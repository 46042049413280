#avantages {
  background-image: url("./images/background-img-avantages.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;

  @media only screen and (max-width: $ss) {
    background-attachment: inherit;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }

  @include title;

  .content {
    display: flex;

    .desinfectionRoom {
      width: 50%;

    }

    .mini-cards {
      margin-bottom: 50px;
      padding-inline: 20px;
      gap: 50px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      overflow: hidden;

      .card {
        font-size: 18px;
        gap: 15px;
        max-width: 650px;
        height: 200px;
        background-color: $wh;
        box-shadow: 15px 20px 10px rgba(0, 0, 0, 0.25);
        display: flex;

        img {
          height: 200px;
          width: 300px;
          object-fit: cover;
        }

        .text {
          justify-content: center;
          display: flex;
          flex-direction: column;
          gap: 10px;

          p {
            color: $bl
          }
        }

        @media only screen and (max-width: $ms) {
          flex-direction: column;
          height: auto;

          .card-img {
            img {
              width: 100%;
            }
          }

          .text {
            padding: 10px;
          }
        }
      }
    }
  }
}