#services {
  @include title;

  .cards-services {
    margin-bottom: 50px;
    padding-inline: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;

    .card {
      width: 350px;
      background-color: $grey;
      box-shadow: 15px 20px 10px rgba(0, 0, 0, 0.25);

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
      }

      .text-card {
        margin: 15px;
        display: flex;
        flex-direction: column;

        h5 {
          color: $bl;
          font-size: 1.3rem;
          font-weight: 780;
          margin-bottom: 13px;
          text-align: center;
        }

        p {
          text-align: center;
        }
      }
    }
  }
}