.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include title;

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;

    .border {
      padding: 20px;
      box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      gap: 20px;

      .description__block {
        display: flex;
        align-items: center;
        max-width: 750px;
        gap: 10px;

        @media only screen and (max-width: $ms) {
          flex-wrap: wrap;
          justify-content: center;
        }

        div {
          color: #5894ca;
          border-radius: 50%;
          padding: 10px;
          box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.5);
          display: flex;

          svg {
            width: 50px;
            height: 50px;
            color: $yl;
          }
        }

        p {
          border-left: 5px solid $yl;
          padding-inline: 20px;
          line-height: 2rem;

          @media only screen and (max-width: $ms) {
            border: none;
            border-top: 5px solid $yl;
            padding: 20px;
            line-height: 1.5rem;
          }

          @media (max-width: $ss) {
            font-size: 13px;
            line-height: 1.2rem;
          }
        }
      }
    }
  }

  .contact-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding-inline: 15px;
    gap: 40px;
    justify-content: center;

    @media only screen and (max-width: 1609px) {
      flex-direction: column-reverse;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 750px;
      width: 100%;
      gap: 15px;

      input,
      textarea {
        padding: 10px;
        border: 1px solid $yl;
        font-size: 14px;
        width: 100%;
        background-color: $grey;
        font-weight: 600;
        color: $yl;
        box-shadow: 15px 10px 15px rgba(0, 0, 0, 0.5);

        &:focus {
          outline: $yl;
        }

        &::placeholder {
          color: $ylGradient;
        }
      }

      textarea {
        min-height: 200px;
      }

      .submit-button {
        display: flex;
        align-items: center;
        gap: 20px;

        button {
          padding: 9px;
          background-color: $grey;
          color: $yl;
          border: 1px solid $yl;
          box-shadow: 15px 10px 15px rgba(0, 0, 0, 0.5);
          cursor: pointer;
          font-size: 15px;
          font-weight: 500;
          transition: background-color 0.3s;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 45px;

          .send,
          .valid-send {
            display: none;
          }

          &:hover {
            background-color: $yl;
            color: $grey;
            padding: 10px;

            p {
              display: none;
            }

            .send {
              display: flex;
            }
          }
        }

        .submit-text {
          display: none;
          font-size: 0.75rem;
        }

        &.valid {
          button {

            p,
            .send {
              display: none;
            }

            .valid-send {
              display: flex;
            }
          }

          .submit-text {
            display: flex;
          }
        }

        @media only screen and (max-width: $ss) {
          flex-direction: column;
          width: 100%;

          .submit-text {
            text-align: center;
          }
        }
      }
    }

    .copyright {
      font-size: 10px;
      text-align: right;
      color: $grey;

      @media only screen and (max-width: $ss) {
        text-align: center;
      }
    }
  }
}