header {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  position: relative;

  @media only screen and (max-width: $ss) {
    background-attachment: inherit;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $bl;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .5);
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 100;
    padding: 5px 20px;
    color: white;

    h1 {
      cursor: pointer;

      img {
        width: 90px;
        height: 90px;
      }
    }

    ul {
      display: flex;
      align-items: center;
      list-style-type: none;
      font-size: 20px;
      gap: 45px;

      @media only screen and (max-width: $ms) {
        gap: 10px;
      }

      li {
        cursor: pointer;
        transition: text-decoration-color 0.3s ease-in,
          text-decoration-thickness 0.3s ease-in;
        font-size: 1rem;

        @media only screen and (max-width: $ms) {
          font-size: .75rem;
        }

        &:hover {
          text-decoration: underline;
          text-decoration-color: $yl;
          text-decoration-thickness: 2px;
        }
        .active {
          font-weight: 900
        }
      }
    }

    .active__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 10;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, .75);
      backdrop-filter: blur(5px);
      height: 100vh;
      width: 100%;

      ul {
        display: flex;
        flex-direction: column;

        li {
          font-size: 2rem;
          padding-block: 20px;
        }
      }
    }

    .nav-mobile {
      display: none;
    }

    @media only screen and (max-width: $ms) {
      ul {
        display: none;
      }

      .nav-mobile {
        display: flex;
      }
    }
  }

  .home {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    color: $wh;

    .home-block {
      backdrop-filter: blur(5px);
      padding: 30px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .animated-heading {
        font-size: 100px;

        @media only screen and (max-width: $ls) {
          font-size: 60px;
        }

        @media only screen and (max-width: $ms) {
          font-size: 3rem;
        }

        @media only screen and (max-width: $ss) {
          font-size: 2rem;
        }
      }

      .button-home {
        display: flex;
        justify-content: center;
        gap: 20px;

        .button {
          border: solid 3px $yl;
          height: 50px;
          padding-inline: 20px;
          font-size: larger;
          background-color: $bl;
          color: $wh;
          position: relative;
          cursor: pointer;

          @media only screen and (max-width: $ls) {
            font-size: medium;
          }

          @media only screen and (max-width: $ms) {
            font-size: small;
          }

          @media only screen and (max-width: $ss) {
            font-size: 11px;
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.5);
            color: black;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          &:hover::before {
            opacity: 0.5;
          }
        }
      }
    }

  }
}

/*FOOTER*/
footer {
  .footer-top {
    margin-top: 100px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
    padding: 50px 20px;
    box-shadow: 0 -10px 10px rgba(0, 0, 0, .5);

    @media only screen and (max-width: $ss) {
      justify-content: flex-start;
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      svg {
        color: $yl;
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }

  }
}

.footer-bottom {
  width: 100%;
  height: 50px;
  background-color: $bl;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
  gap: 20px;
  font-size: 10px;

  @media only screen and (max-width: $ss) {
    gap: 0;
  }

  .mentions {
    display: flex;
    gap: 20px;
    color: white;
  }

  .copyright a {
    text-decoration: none;
    color: white;
  }
}