.home-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media only screen and (max-width: $ms) {
    flex-direction: column;
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
  }

  .left {
    z-index: 10;
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    @media only screen and (max-width: $ms) {
      width: 100%;
      height: 50%;
    }
  }

  .right {
    z-index: 10;
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    @media only screen and (max-width: $ms) {
      width: 100%;
      height: 50%;
      bottom: 0;
      top: unset;
    }
  }

  .home {
    width: 50%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $ms) {
      width: 100%;
    }

    .home-menu {
      width: 400px;
      height: 100%;
      backdrop-filter: blur(40px);
      position: relative;
      transition: all 0.3s ease-in;
      display: flex;

      @media only screen and (max-width: $ms) {
        width: 100%;
      }

      .menu {
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: $ms) {
          align-items: center;
        }

        h2 {
          color: $wh;
          font-size: 1.8rem;
          z-index: 10;
          width: 100%;
          padding-block: 10px;

          @media only screen and (max-width: $ms) {
            font-size: 1.3rem;
          }

          @media only screen and (max-width: $ss) {
            font-size: 17px;
          }
        }

        p {
          color: $wh;
          font-size: 12px;
          display: flex;
          text-align: center;

          @media only screen and (max-width: $ss) {
            font-size: 10px;
          }
        }
      }
    }

    &.trois-d {
      background-image: url("./images/acceuil.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      justify-content: right;

      .home-menu {
        clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 0% 50%);
        justify-content: right;
        padding-right: 100px;
        align-items: center;

        @media only screen and (max-width: $ms) {
          justify-content: center;
          clip-path: polygon(100% 100%, 50% 0%, 50% 0%, 0% 100%);
          padding: 0;
          align-items: end;
          padding-bottom: 55px;
        }
      }
    }

    &.curage {
      background-image: url("./images/background-modal-vidangeur.webp");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      justify-content: left;

      .home-menu {
        clip-path: polygon(100% 50%, 0% 100%, 0% 100%, 0% 0%);
        justify-content: left;
        align-items: center;
        padding-left: 100px;

        @media only screen and (max-width: $ms) {
          justify-content: center;
          clip-path: polygon(100% 0%, 50% 0%, 0% 0%, 50% 100%);
          padding: 0;
          align-items: start;
          padding-top: 50px;
        }
      }
    }
  }

  .logo {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: $ms) {
      flex-direction: column-reverse;
    }

    img {
      width: 180px;
      height: 180px;

      @media only screen and (max-width: $ms) {
        width: 100px;
        height: 100px;
      }
    }

    .contact-accueil {
      color: $wh;
      font-size: 14px;
      text-align: center;

      @media only screen and (max-width: $ms) {
        font-size: 12px;
        display: none;
      }

      @media only screen and (max-width: $ss) {
        font-size: 10px;
      }
    }
  }
}