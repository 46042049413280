$ss: 425px;
$ms: 768px;
$ls: 1024px;

$yl : #5894CA;
$bl :#2c2c2c;
$wh : white;
$grey : #f5f5f5;
$ylGradient : rgb(88, 148, 202, .5);

#service,
.footer {
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

@mixin title {
  padding-block: 130px;

  @media (max-width: $ms) {
    padding-block: 100px;
  }

  @media (max-width: $ss) {
    padding-block: 50px;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 150px;
    z-index: 1;
    @media (max-width: $ss) {
     margin-bottom: 50px;
    }
    h3 {
      font-size: 3rem;

      @media only screen and (max-width: $ms) {
        font-size: 2rem;
      }

      @media only screen and (max-width: $ss) {
        font-size: 1.5rem;

      }
    }

    hr {
      width: 100%;
      height: 10px;
      border: none;
      background: linear-gradient(transparent, $yl);
    }
  }
}


ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: unset;
}

.modal-open {
  overflow: hidden;
}