.banner-eco {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 350px;

    @media only screen and (max-width: $ss) {
        background-attachment: inherit;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 350px;
        width: 100%;
        background-color: rgb(255, 255, 255, .5);
    }

    h3 {
        font-size: 1.5rem;
        font-style: italic;
        font-weight: 400;
        width: 75%;
        z-index: 1;
        padding: 10px;
        backdrop-filter: blur(5px);

        @media only screen and (max-width: $ms) {
            font-size: 1rem;
        }

        @media only screen and (max-width: $ss) {
            font-size: .85rem;
        }
    }
}